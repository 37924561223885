import React from "react";
import { GothamRegular } from "../../../components/Text";
import { arraySplitter, windowDimension } from "../../../components/tools";
import { CheckBox } from "../../../components/Checkbox";
import { useRegisterField } from "../../../components/inputs";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

export const ProductNeeds = ({ useFormObj, name, label, options, error }) => {
  const { isTabOrPhone: isResponsive } = windowDimension();
  const { watch, setValue } = useFormObj;
  const { t } = useTranslation("register/register");

  const setVal = (value) => setValue(name, value);

  const values = watch(name) || [];

  const array = arraySplitter(options, 5);

  const isError = !isEmpty(error);

  useRegisterField(useFormObj, name);

  const labelDecider = () => {
    if (isError) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 87,
            gap: 4,
            width: "100%",
          }}
        >
          <GothamRegular className="font12" style={{ color: "#E73126" }}>
            {label}
          </GothamRegular>
          <GothamRegular className="font10" style={{ color: "#E73126" }}>
            {`${t("(Please choose at least one)")}`}
          </GothamRegular>
        </div>
      );
    }
    return <GothamRegular className="font12">{label}</GothamRegular>;
  };

  return (
    <div
      className="d-flex"
      style={{
        marginBottom: 32,
        flexDirection: "column",
        gap: 20,
      }}
    >
      {labelDecider()}
      <div
        style={{
          display: "flex",
          justifyContent: isResponsive ? "flex-start" : "space-between",
          gap: 20,
        }}
      >
        {array.map((array, index) => {
          return (
            <div
              key={index}
              className="d-flex"
              style={{
                width: isResponsive ? "50%" : "auto",
                flexDirection: "column",
                gap: 16,
              }}
            >
              {array.map((item, index) => {
                const { label, value, desc } = item || {};
                const isActive = values.includes(value);
                return (
                  <div
                    className="d-flex hover"
                    key={index}
                    style={{
                      gap: 8,
                      alignItems: "start",
                    }}
                    onClick={() => {
                      if (!isActive) return setVal([...values, value]);
                      const filtered = values.filter((val) => value !== val);

                      setVal(isEmpty(filtered) ? undefined : filtered);
                    }}
                  >
                    <CheckBox isActive={isActive} />
                    <div className="d-flex-column">
                      <GothamRegular>{t(label)}</GothamRegular>
                      {desc && (
                        <GothamRegular
                          className="font10"
                          style={{ color: "#909098", marginTop: 4 }}
                        >
                          {t(desc)}
                        </GothamRegular>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
