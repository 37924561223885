import { GothamMedium, GothamRegular, TextInlineMedium } from "components/Text";
import { Layout } from "../components";
import { JackIcons } from "assets/jackIcons/parent";
import { PinInput } from "components/PINModal/pin";
import { ButtonJack } from "components/ButtonsJack/parent";
import { Modal } from "reactstrap";
import { useEffect, useState } from "react";
import { useMutation } from "tools/api";
import { jackColors } from "assets/colors";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { eventsTracker } from "universalFunctions/events";
import { EMAIL_VERIFIED } from "pageComponents/kybJackComponents/parent";

const ICON = ({ icon, color }) => (
  <div
    style={{
      width: 40,
      height: 40,
      borderRadius: 40,
      backgroundColor: "black",
      display: "grid",
      placeItems: "center",
    }}
  >
    <JackIcons name={icon} fill={color} />
  </div>
);

const OTPLayout = ({ children }) => {
  return (
    <div style={{ height: "100vh", width: "100vw", padding: "32px 0px" }}>
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 32,
            alignItems: "center",
            width: "auto",
            maxWidth: 438,
          }}
        >
          {children}
        </div>
      </Layout>
    </div>
  );
};

const textGrey = { color: "#909098" };

const RegisterOTP = ({ isOpen, toggle, email = "", toLogin }) => {
  const { t } = useTranslation("register/register");
  const [isVerified, setIsVerified] = useState(false);
  const [pin, setPin] = useState("");
  const [timer, setTimer] = useState(0);
  const [hasResend, setHasResend] = useState(false);
  const { push, query, pathname } = useRouter();

  const { mutation: resendOTP } = useMutation({
    url: "/business_users/send_email_otp",
    method: "post",
    // afterSuccess: () => {
    //   setTimer(60);
    //   setHasResend(true);
    // },
  });
  const { mutation: validateOTP, loading: loadingValidate } = useMutation({
    url: "/business_users/validate_email_otp",
    method: "post",
    afterSuccess: () => {
      setIsVerified(true);
      push({ pathname, query: { ...query, isRegisterSuccess: true } });
    },
  });

  const ResendText = (props) => <GothamMedium className="font12" {...props} />;

  useEffect(() => {
    if (!timer) return;
    let interval = setInterval(async () => {
      setTimer((prevTime) => {
        if (prevTime < 1) return prevTime;
        const result = prevTime - 1;
        return result;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);
  if (isVerified)
    return (
      <Modal
        className="my-0"
        contentClassName="modal-full"
        isOpen={isOpen}
        toggle={toggle}
        keyboard={false}
      >
        <Layout>
          <ICON icon={"checkmark_outline"} color={"#B9FC00"} />
          <div style={{ textAlign: "center" }}>
            <GothamMedium className="font24">
              {t("Your email is now verified!")}
            </GothamMedium>
            <GothamRegular style={{ color: "#909098", marginTop: 8 }}>
              {t(
                "To finalize your setup, please log in and complete the business verification process."
              )}
            </GothamRegular>
          </div>
          <ButtonJack
            className="w-100"
            onClick={() => {
              if (toLogin) return toLogin();
              eventsTracker(EMAIL_VERIFIED);
              push("/login");
            }}
          >
            {t("Log in to Jack")}
          </ButtonJack>
        </Layout>
      </Modal>
    );

  return (
    <Modal
      className="my-0"
      contentClassName="modal-full"
      isOpen={isOpen}
      toggle={toggle}
      keyboard={false}
    >
      <OTPLayout>
        <ICON icon={"markunread_mailbox"} color={"white"} />
        <div style={{ textAlign: "center" }}>
          <GothamMedium className="font24">
            {t("Input Verification Code")}
          </GothamMedium>
          <GothamRegular style={{ ...textGrey, marginTop: 8 }}>
            {t("We have sent the OTP code via email to")}{" "}
            <TextInlineMedium style={{ color: "#343434" }}>
              {email}
            </TextInlineMedium>
          </GothamRegular>
        </div>
        <PinInput
          isPassword={false}
          length={7}
          onChange={(pin) => setPin(pin)}
          value={pin}
          setValue={setPin}
          onSubmit={() => {
            if (pin.length < 7) return;
            // console.log({ pin });
            // validateOTP({ pin, email });
          }}
        />
        <ButtonJack
          className="w-100"
          style={{ width: "auto" }}
          onClick={() => validateOTP({ otp: pin, email })}
          isLoading={loadingValidate}
          disabled={pin?.length < 7}
        >
          {t("Continue")}
        </ButtonJack>
        <GothamRegular style={{ ...textGrey }}>
          {t("Didn't get the email?")}
        </GothamRegular>
        {/* {timer ? (
          <ResendText style={{ color: jackColors.grey90 }}>
            {"Code has been sent"} ({timer}
            {"s"})
          </ResendText>
        )} */}

        <GothamRegular style={{ ...textGrey }}>
          {t("Check spam folder or")}{" "}
          {timer > 0 ? (
            <TextInlineMedium style={{ color: "#909098" }}>
              {t("get a new link")} {` (${timer}s)`}
            </TextInlineMedium>
          ) : (
            <TextInlineMedium
              style={{ color: "#343434", cursor: "pointer" }}
              onClick={() => {
                resendOTP({ email });
                setTimer(60);
                setHasResend(true);
              }}
            >
              {t("get a new link")}
            </TextInlineMedium>
          )}
        </GothamRegular>

        {hasResend ? (
          <div
            style={{
              width: "100%",
              border: "1px solid #BBBBC0",
              backgroundColor: "#F1F1F1",
              borderRadius: 4,
              display: "grid",
              placeItems: "center",
              height: 44,
            }}
          >
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <JackIcons name="info" fill="#909098" />{" "}
              <GothamMedium className="font12">
                {t("Email sent. Please check your inbox.")}
              </GothamMedium>
            </div>
          </div>
        ) : null}
      </OTPLayout>
    </Modal>
  );
};

export default RegisterOTP;
