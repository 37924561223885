import { useForm, windowDimension } from "@tools";
import { phoneCode } from "assets/phoneCode";
import { titleCase } from "change-case";
import SwitchLocaleButton from "components/LocaleButton";
import { useModalHook } from "components/Modals";
import { SimpleTextAreaJack } from "components/inputs/simpleTextArea";
import { capitalize, pickBy } from "lodash";
import { useRouter } from "next/router";
import {
  HIT_REGISTER_EVENT,
  REGISTRATION_PAGE,
  SELECT_PRODUCT_NEEDS,
} from "pageComponents/kybJackComponents/parent";
import { useLanguage } from "public/locales/translationFunctions";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { decryptToken } from "tools/api/functions/hash";
import { eventsTracker, setUserEvents } from "universalFunctions/events";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { GothamRegular } from "../../../components/Text";
import { PhoneSelectionJack } from "../../../components/inputs/selection";
import {
  TextFieldJack,
  TextFieldJackPassword,
  TextFieldJackState,
} from "../../../components/inputs/textfield";
import JackLogo from "../../../assets/images/jack-logo.svg";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { useMutation } from "../../../tools/api";
import { Layout, PasswordChecker } from "../components";
import styles from "../layout.module.css";
import { ServiceAgreementCheckbox } from "./agreement";
import { errorWatcher, validationChecker } from "./checker";
import RegisterOTP from "./registerOTP";
import { ProductNeeds } from "./selection";
import { SidebarRegister } from "./sidebar";

const FirstStep = ({
  setCurrentScreen,
  setCurrentStep,
  payload,
  setPayload,
  isCreatePassword,
  onSubmit,
  setEmailSent,
  banner = false,
  loading,
}) => {
  const {
    isOpen: isOpenRegistOTP,
    close: closeRegistOTP,
    open: openRegistOTP,
  } = useModalHook();

  //====================FOR MIXPANEL PURPOSE====================//
  const [hasSelectProduct, setHasSelectProduct] = useState(false);

  //================================================================//

  const { t } = useTranslation("register/register");
  const { isBahasa: isID } = useLanguage();
  const { push, query, locale } = useRouter();

  const { isTabOrPhone: isResponsive, width } = windowDimension();
  const {
    password: passwordQ,
    password_confirmation: password_confirmationQ,
    referral_code: referral_code,
    utm_source = "",
    utm_medium = "",
    utm_campaign = "",
    page_source = "",
    event_name = "",
    average_monthly_revenue = "",
    utm_notes = "",
    invitation_token,
    is_admin,
  } = query;

  const isAdmin = is_admin != null && is_admin === "true";

  const isInvited = Boolean(invitation_token) && isAdmin;

  const currentBanner = query?.banner;

  const isNewUser = !!currentBanner;

  const querySanitizer = (ps) => {
    if (ps.includes("?")) {
      const qsIndex = ps?.indexOf("?");
      return ps.slice(0, qsIndex);
    }
    return ps;
  };

  const pageSourceQuery = !!page_source
    ? { page_source: querySanitizer(page_source).toLowerCase() }
    : {};

  const marketingData = {
    ...pageSourceQuery,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_notes,
    banner: currentBanner || null,
    event_name,
    average_monthly_turnover: average_monthly_revenue,
  };

  const {
    email: email_query = "",
    name: name_query = "",
    business_name: business_name_query = "",
    mobile: mobile_query = "",
    business_id: business_id_query = null,
  } = query ?? {};

  const emailQuery = email_query ? email_query.replace(/ /g, "+") : "";

  const defaultValues = query?.password
    ? {
        ...query,
        password: decryptToken(passwordQ),
        password_confirmation: decryptToken(password_confirmationQ),
      }
    : query?.mobile && query.name && query.email && query.business_name
    ? {
        name: name_query,
        business_name: business_name_query,
        email: emailQuery,
        mobile: mobile_query,
        // mobile_country_iso: "",
        password: "",
        password_confirmation: "",
        referral_code: referral_code || "",
        sa: false,
        introduced_to_jack: "",
      }
    : {
        name: "",
        business_name: "",
        email: "",
        mobile: "",
        // mobile_country_iso: "",
        password: "",
        password_confirmation: "",
        referral_code: referral_code || "",
        sa: false,
        introduced_to_jack: "",
      };

  const useFormObj = useForm({
    defaultValues,
  });

  const { errorSnackBar } = ToasterHook();

  const [error, setError] = useState({});
  const { watch, handleSubmit, setValue } = useFormObj;
  const products = watch("products");
  const passwordWatch = watch("password");

  const loginHandler = () => {
    push("/login");
  };

  const trackerValueDecider = () => {
    const isGeneral = currentBanner === "general";
    const isTransfezApp = currentBanner === "transfez_app";

    if (!isNewUser)
      return {
        page_title: "registration_page_no_banner",
        page_source: "",
      };

    if (isGeneral)
      return {
        page_title: "registration_page_general_banner",
        page_source,
      };

    if (isTransfezApp)
      return {
        page_title: "registration_page_transfez_app_banner",
        page_source,
      };

    // add below for new banner content
  };

  useEffect(() => {
    if (emailQuery) return setValue("email", emailQuery);
    // if (name_query) return setValue("name", name_query);
    // if (business_name_query)
    //   return setValue("business_name", business_name_query);
    // if (mobile_query) return setValue("business_name", mobile_query);
  }, [emailQuery]);

  const params = isNewUser
    ? {}
    : {
        demo_business_name: business_name_query,
        demo_email: emailQuery,
        demo_business_id: business_id_query,
      };

  useEffect(() => {
    eventsTracker("page_view", {
      ...trackerValueDecider(),
      ...params,
    });
    eventsTracker(REGISTRATION_PAGE, {
      ...trackerValueDecider(),
      ...params,
    });
  }, []);

  useEffect(() => {
    const productsNotEmpty = products?.length > 0;
    if (productsNotEmpty && !hasSelectProduct) {
      setHasSelectProduct(true);
      eventsTracker(SELECT_PRODUCT_NEEDS);
    }
  }, [products]);

  const isTransfezBanner = currentBanner === "transfez_app";

  // const registerTrackerDecider = isTransfezBanner
  //   ? { registration_page_transfez_app_banner: currentBanner }
  //   : { registration_page_general_banner: currentBanner };
  const { mutation: mutationRegister, loading: loadingRegister } = useMutation({
    type: "long",
    url: "/business_partners",
    method: "post",
    afterSuccess: (res, payload) => {
      // setCurrentScreen("checkEmailRegister");
      // setEmailSent(payload.user.email); // payload submit
      // const { password, password_confirmation, ...userWoPass } = payload.user;
      // payload.user = userWoPass;

      const { user, business } = payload;

      const filteredUser = pickBy(user, (_, key) => !key.includes("pass"));

      const eventsObj = { ...filteredUser, partner: { name: business?.name } };

      setUserEvents(eventsObj);

      const { data } = res?.data ?? {};
      const { id: prod_business_id } = data ?? {};
      const { email: prod_email } = payload?.user ?? {};
      const bannerQuery = isNewUser ? `&banner=${currentBanner}` : "";
      eventsTracker(HIT_REGISTER_EVENT, {
        payload,
        page_title: isTransfezBanner
          ? "registration_page_transfez_app_banner"
          : "registration_page_general_banner",
        demo_business_id: +business_id_query,
        demo_business_name: name_query,
        demo_email: emailQuery, // kalo emailnya ganti gimana ttp demo email?
        prod_business_id,
        prod_email,
        selected_product_types: products,
        selected_product_count: products?.length,
      });
      // push(`/register/success?email=${payload.user.email}${bannerQuery}`);
      openRegistOTP();
    },
    withError: false,
    handleError: (err) => {
      const { data } = err?.response ?? {};
      const object = data?.message;
      const keys = Object.keys(object);

      const messages = keys.map((key) => {
        const value = object[key];
        return `${titleCase(key)} ${value}`;
      });
      const msg = capitalize(messages.flat(1)[0]);
      const cap = t(msg);
      errorSnackBar({ msg: cap });
    },
  });

  const defaultArr = [
    "name",
    "country_code",
    "mobile",
    "password",
    "password_confirmation",
  ];

  const defaultWatchers = isCreatePassword
    ? [...defaultArr, "email"]
    : [...defaultArr, "business_name", "sa", "products", "email"].filter(
        (item) => item
      );

  const formWatch = watch(defaultWatchers);

  errorWatcher({ formWatch, error });

  const errorFormatter = (error) => {
    const errorMessage = error?.response?.data?.error?.message || "";
    const isEmailNotExist =
      errorMessage.includes("40101") ||
      errorMessage.includes("Email is not registered in the database");
    const isEmailExist = errorMessage === "Invalid password";

    return { isEmailNotExist, isEmailExist };
  };

  const registerDisabled = formWatch.sa === false;

  const submit = async (data) => {
    const isError = validationChecker({
      formWatch,
      setError,
      t,
      isInvited,
      isID,
    });
    if (isError) return;

    if (onSubmit) return onSubmit(data);

    const {
      name,
      business_name,
      email,
      mobile,
      password,
      password_confirmation,
      referral_code,
      country_code,
      products,
      introduced_to_jack,
    } = data || {};

    const registerPayload = {
      user: {
        name,
        email,
        mobile,
        password,
        password_confirmation,
        country_code:
          phoneCode.find(({ code }) => code == country_code)?.value || "+62",
      },
      business: {
        name: business_name,
        referral_code,
        product_needs: { products },
        marketing_need: {
          ...marketingData,
        },
        additional_info: {
          introduced_to_jack,
        },
      },
    };

    mutationRegister(registerPayload);
  };

  const buttonStyle = {
    marginLeft: "auto",
    width: "40px",
    padding: 0,
  };

  const textWithRedAsterix = (text) => (
    <>
      {text}
      <span style={{ color: "red" }}>*</span>
    </>
  );

  const password = (
    <>
      <TextFieldJackPassword
        useFormObj={useFormObj}
        label={textWithRedAsterix("Password")}
        placeholder={t("Enter your password")}
        name="password"
        error={t(error["password"])}
      />
      <PasswordChecker
        className={
          passwordWatch
            ? styles["password-checker-visible"]
            : styles["password-checker-hidden"]
        }
        isError={t(error?.pass_template)}
        password={passwordWatch}
      />
      <TextFieldJackPassword
        useFormObj={useFormObj}
        label={textWithRedAsterix(t("Confirm Password"))}
        placeholder={t("Repeat your password")}
        name="password_confirmation"
        error={t(error["password_confirmation"])}
      />
    </>
  );

  const mobile = (
    <div
      style={{
        display: "flex",
        gap: "16px",
      }}
    >
      <PhoneSelectionJack
        name="country_code"
        useFormObj={useFormObj}
        label={t("Country Code")}
      />
      <TextFieldJack
        name="mobile"
        label={textWithRedAsterix(t("Phone Number"))}
        placeholder="E.g., 8123456789"
        type="number"
        useFormObj={useFormObj}
        style={{ width: "100%" }}
        error={t(error["mobile"])}
      />
    </div>
  );

  const array = [
    { label: "Business Checking Account", value: "business_checking_account" },
    { label: "International Transfer", value: "international_transfer" },
    { label: "Virtual Account", value: "va_collection" },
    { label: "Payroll", value: "payroll" },
    {
      label: "API Integration",
      value: "api_integration",
      desc: "(Virtual Account Collection, Local Transfer, International Transfer)",
    },
    { label: "Corporate Cards", value: "cards" }, // take it back for kyb enhancement
    { label: "Local Transfer", value: "local_transfer" },
    { label: "Reimbursement", value: "reimbursement" },
    { label: "Bill Payment", value: "invoice_payment" },
  ];

  const formWidthDecider = () => {
    if (isResponsive) return "100vw";
    if (isNewUser) return "calc(100vw - 432px)";
    return "100vw";
  };

  if (isCreatePassword)
    return (
      <Layout
        title={t("Take the first step by creating your Jack account")}
        setCurrentScreen={setCurrentScreen}
        woJumper
      >
        <div style={{ position: "absolute", top: 20, right: 20 }}>
          <SwitchLocaleButton noAuth />
        </div>
        <div className={styles["form"]}>
          {!isInvited && (
            <TextFieldJack
              name="name"
              useFormObj={useFormObj}
              label={t("Full Legal Name")}
              placeholder={t("Enter your full legal name")}
              error={t(error["name"])}
            />
          )}
          <TextFieldJackState
            value={emailQuery}
            label={t("Email")}
            disabled
            helperText={t(
              "This email will be used to complete your company registration process."
            )}
          />
          {password}
          {!isInvited && mobile}
          <ButtonJack
            onClick={handleSubmit(submit)}
            isLoading={loading || loadingRegister}
            style={buttonStyle}
          >
            {t("Register")}
          </ButtonJack>
        </div>
      </Layout>
    );

  const isIndo = locale == "id";

  const introducedLabel = isIndo ? (
    <>
      Siapa yang memperkenalkan Jack kepada Anda?{" "}
      <span className="font10" style={{ color: jackColors.grey90 }}>
        (Opsional)
      </span>
    </>
  ) : (
    <>
      Who introduced you to Jack?{" "}
      <span className="font10" style={{ color: jackColors.grey90 }}>
        (Optional)
      </span>
    </>
  );

  return (
    <div
      // className={registerStyles["wrapper"]}
      style={{
        display: "flex",
        flexDirection: isResponsive ? "column" : "row",
      }}
    >
      {/* <div className={"d-flex"}> */}
      {isNewUser && (
        <div style={isResponsive ? { minWidth: 100 } : { minWidth: 432 }}>
          <SidebarRegister
            currentBanner={currentBanner}
            withNavMenu={isResponsive}
            wrapperStyle={
              isResponsive
                ? { width: "fit-content" }
                : { position: "fixed", top: "0px", paddingTop: 0 }
            }
            iconStyle={isResponsive ? {} : { height: 80 }}
            // wrapperClass={registerStyles["sidebar-content"]}
          />
        </div>
      )}

      <div
        className={`d-flex align-items-center`}
        style={{
          width: formWidthDecider(),
          flexDirection: "column",
        }}
      >
        {/* {!isResponsive && (
          <div
            className="w-100 align-items-end justify-content-end d-flex"
            style={{ padding: 20, paddingRight: 32, gap: 16 }}
          >
            <SwitchLocaleButton noAuth />
            <ButtonJack
              type="outline"
              rightIcon={<JackIcons name="log-out-outline" fill="black" />}
              onClick={() => push("/login")}
            >
              {t("Log in")}
            </ButtonJack>
          </div>
        )} */}
        <div
          style={
            isResponsive
              ? {
                  maxWidth: width,
                }
              : { width: 462 }
          }
        >
          {/* <GothamMedium
            className="font24"
            style={{ textAlign: "center", marginBottom: 32, marginTop: 24 }}
          >
            Take the first step by creating <br />
            your Jack account
          </GothamMedium> */}
          {/* {contentDecider()} */}
          <Layout
            title={t("Take the first step by creating your Jack account")}
            setCurrentScreen={setCurrentScreen}
            woJumper={isCreatePassword}
            // wrapperStyle={
            //   isResponsive
            //     ? { maxWidth: width, paddingTop: 108 }
            //     : { paddingTop: 108 }
            // }
            wrapperStyle={{
              paddingTop: 108,
              position: isResponsive ? "relative" : "inherit",
            }}
            woIcon
          >
            {/* <div style={{ position: "absolute", top: 20, right: 20 }}>
              <SwitchLocaleButton noAuth />
            </div> */}
            <div
              style={{
                backgroundColor: "white",
                position: "fixed",
                width: "100%",
                top: 0,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: isResponsive ? 16 : 32,
                paddingRight: isResponsive ? 16 : 32,
                zIndex: 1,
              }}
            >
              <div
                style={{
                  // padding: 20,
                  paddingLeft: isResponsive ? 0 : 32,
                  // gap: 16,
                  // position: "absolute",
                  // top: 20,
                  // left: 20,
                }}
              >
                <img
                  src={JackLogo}
                  style={{
                    height: isResponsive ? 32 : 40,
                    minHeight: isResponsive ? 32 : 40,
                    maxHeight: isResponsive ? 32 : 40,
                  }}
                />
              </div>
              <div
                className="w-100 align-items-end justify-content-end d-flex"
                style={{
                  padding: 20,
                  paddingRight: isResponsive ? 0 : 32,
                  gap: isResponsive ? 8 : 16,
                  // position: "absolute",
                  // top: 20,
                  // right: 20,
                }}
              >
                <SwitchLocaleButton noAuth />
                <ButtonJack
                  type="outline"
                  rightIcon={<JackIcons name="log-out-outline" fill="black" />}
                  onClick={() => push("/login")}
                >
                  {t("Log in")}
                </ButtonJack>
              </div>
            </div>

            <form
              className={styles["form"]}
              onSubmit={handleSubmit(submit)}
              style={isResponsive ? { padding: `0px 12px` } : {}}
            >
              <TextFieldJack
                name="name"
                label={textWithRedAsterix(t("Full Legal Name"))}
                placeholder={t("Enter your full legal name")}
                useFormObj={useFormObj}
                error={t(error["name"])}
              />
              <TextFieldJack
                name="business_name"
                label={textWithRedAsterix(t("Business Legal Name"))}
                placeholder={t("Enter your business name")}
                useFormObj={useFormObj}
                error={t(error["business_name"])}
              />
              <TextFieldJack
                name="email"
                label={textWithRedAsterix(t("Email"))}
                placeholder={t("Enter your email")}
                useFormObj={useFormObj}
                error={t(error["email"])}
              />
              {/* <TextFieldJackState
                name="email"
                label="Email"
                useFormObj={useFormObj}
                error={t(error["email"])}
                value={formWatch.email}
                // helperText="This email will be used to complete your company registration process."
                // placeholder="Enter your email"
                required
              /> */}
              {password}
              {mobile}
              <ProductNeeds
                useFormObj={useFormObj}
                name="products"
                label={textWithRedAsterix(t("Product Needs"))}
                options={array}
                error={t(error["products"])}
              />
              <SimpleTextAreaJack
                useFormObj={useFormObj}
                name="introduced_to_jack"
                maxLength={120}
                placeholder={t(
                  "Enter the company name that referred you to us"
                )}
                label={
                  <>
                    {/* Who introduced you to Jack?{" "}
                    <span
                      className="font10"
                      style={{ color: jackColors.grey90 }}
                    >
                      (Optional)
                    </span> */}
                    <Trans
                      i18nKey={t("Dynamic.introduce")}
                      components={[
                        <span
                          className="font10"
                          style={{ color: jackColors.grey90 }}
                        />,
                      ]}
                    />
                  </>
                }
              />
              <ServiceAgreementCheckbox name="sa" useFormObj={useFormObj} />
              <ButtonJack
                // disabled={isButtonDisabled}
                // rightIcon={rightIcon}
                disabled={registerDisabled}
                isLoading={loadingRegister}
                style={buttonStyle}
              >
                {t("Register")}
              </ButtonJack>
            </form>
            {isResponsive && (
              <GothamRegular
                style={{ marginTop: 26, color: jackColors.neutral900 }}
              >
                {t("Already have an account?")}
                <span
                  // className="link"
                  style={{
                    marginLeft: 5,
                    fontWeight: "bolder",
                    textDecoration: "none",
                    fontFamily: "GothamBook",
                    fontSize: 14,
                    borderBottom: `1px solid ${jackColors.neutral900} `,
                  }}
                  onClick={() => push("/login")}
                >
                  {t("Log In")}
                </span>
              </GothamRegular>
            )}
          </Layout>
        </div>
      </div>
      {/* <img src="/images/sidebar.png" style={{ height: "100vh" }} /> */}
      <RegisterOTP
        isOpen={isOpenRegistOTP}
        email={watch("email")}
        toggle={closeRegistOTP}
      />
    </div>
  );
};

export default FirstStep;
