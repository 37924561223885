import { Trans, useTranslation } from "react-i18next";
import { CheckBox } from "../../../components/Checkbox";
import { GothamRegular, TextInlineMedium } from "../../../components/Text";
import { useRegisterField } from "../../../components/inputs";
import { windowDimension } from "../../../components/tools";
import { useRouter } from "next/router";

export const ServiceAgreementCheckbox = ({ useFormObj, name }) => {
  const { isTabOrPhone: isResponsive } = windowDimension();
  const { setValue, watch } = useFormObj;
  useRegisterField(useFormObj, name);
  const { t } = useTranslation("register/register");

  const value = !!watch(name);
  const onClick = (value) => window.open(`/tnc-login?value=${value}`, "_blank");

  const { locale } = useRouter();

  const isIndo = locale == "id";

  const Medium = ({ children, onClick }) => (
    <TextInlineMedium
      onClick={onClick}
      style={{ textDecorationLine: "underline" }}
      className="hover"
    >
      {children}
    </TextInlineMedium>
  );

  const text = (array) => (
    <GothamRegular style={{ marginLeft: 8 }}>
      {array[0]} <Medium onClick={() => onClick(1)}>{array[1]}</Medium>{" "}
      {array[2]} {isResponsive && <br />}
      <Medium onClick={() => onClick(2)}>{array[3]}</Medium>
    </GothamRegular>
  );

  const arr = isIndo
    ? ["Saya menyetujui", "Perjanjian Layanan", "dan", "Kebijakan Privasi Jack"]
    : ["I agree to Jack’s", "Service Agreement", "and", "Privacy Policy"];

  return (
    <div style={{ marginBottom: 32 }} className="d-flex">
      <CheckBox
        isActive={value}
        onClick={() => setValue(name, !value)}
        alignItems={isResponsive ? "align-items-start" : null}
      />
      <GothamRegular style={{ marginLeft: 8 }}>
        {/* I agree to Jack’s{" "}
        <TextInlineMedium
          style={{ textDecorationLine: "underline" }}
          className="hover"
          onClick={() => onClick(1)}
        >
          Service Agreement
        </TextInlineMedium>{" "}
        and {isResponsive && <br />}
        <TextInlineMedium
          style={{ textDecorationLine: "underline" }}
          className="hover"
          onClick={() => onClick(2)}
        >
          Privacy Policy
        </TextInlineMedium> */}
        <Trans
          i18nKey={t("Dynamic.agree")}
          components={[
            <TextInlineMedium
              style={{ textDecorationLine: "underline" }}
              className="hover"
              onClick={() => onClick(1)}
            />,
            <TextInlineMedium
              style={{ textDecorationLine: "underline" }}
              className="hover"
              onClick={() => onClick(2)}
            />,
          ]}
        />
      </GothamRegular>
    </div>
  );
};
